<template>
  <div style="min-height: 750px;">
    <b-card no-body :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']">


      <b-row :style="d_fullScreen ? 'position: fixed; top: 0; left: 0; width: 100%; height: 50px; z-index: 1001; background-color: white; border-bottom: solid 1px #a5aaad; box-shadow: 1px 1px 1px 0px; margin: 0px;' : ''">
        <b-col sm="12" lg="6">
          <b-dropdown id="dropdown-grouped" variant="outline-white" no-caret style="width: 100%;">
            <template #button-content>
              <!-- <i class="fa fa-list"></i> -->
              <div style="text-align: left; padding: 5px;">
                &#x1f50d;
                <span v-if="d_selectedProjectType && d_projectList[d_selectedProjectType] && d_selectedProjectIndex !== ''">
                  {{ d_projectList[d_selectedProjectType][d_selectedProjectIndex]['name'][StoreLang] }}
                </span>
                <span v-else>
                  {{ StoreLangTranslation.data['projects'][StoreLang]  }}
                </span>
                <span v-if="d_selectedProjectType && d_projectList[d_selectedProjectType] && d_selectedProjectIndex !== ''" style="margin: 5px;">
                  {{ d_selectedProjectIndex !== '' && d_projectList[d_selectedProjectType][d_selectedProjectIndex]['wanalyzer_project_create_date'] ? f_dateFormat(d_projectList[d_selectedProjectType][d_selectedProjectIndex]['wanalyzer_project_create_date'], 'year-month-day hh:mm') : '' }}
                </span>
              </div>
            </template>
            <b-row style="width: 100%;">
              <b-col sm="12" lg="1"></b-col>
              <b-col sm="12" lg="8">
                <b-form-input v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
              </b-col>
              <b-col sm="12" lg="3">
                <i class="fa fa-trash" style="cursor: pointer;" @click="d_searchText = ''"></i>
              </b-col>
            </b-row>
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px;" >
              <template v-for="(project_list, project_type) in d_projectList">
                  <template v-if="project_list.length > 0 && f_searchInProjectList(project_list)">
                    <b-dropdown-group :id="project_type" :header="StoreLangTranslation.data[project_type][StoreLang]" style="min-width: 500px;">
                      <template v-if="project_type === 'wanalyzer_create_query'" >
                        <template v-for="(scope, scopeIndex) in d_scopeList">
                          <b-row v-b-toggle="'project_collapse_' + scopeIndex" >
                            <b-col cols="1"></b-col>
                            <b-col cols="11">
                              <i class="fa fa-chevron-down"></i>
                              <span> {{ scope }}</span>
                           </b-col>
                          </b-row>
                          <b-collapse :id="'project_collapse_' + scopeIndex" :visible="false">
                            <template v-if="project['wanalyzer_project_scope'] && project['wanalyzer_project_scope'] === scope && f_searchItem(project.name[StoreLang])" v-for="(project, project_index) in project_list">
                              <b-dropdown-item-button @click="f_selectProject(project_type, project_index)" :style="d_selectedProjectType === project_type && project_index === d_selectedProjectIndex ? 'background-color: #a7d2ff;' : ''">
                                <b-row>
                                  <b-col cols="1"></b-col>
                                  <b-col cols="11">
                                    {{ project.name[StoreLang] }}
                                  </b-col>
                                </b-row>
                              </b-dropdown-item-button>
                            </template>
                          </b-collapse>
                        </template>
                        <template>
                          <b-row v-b-toggle="'project_collapse_no_scope'" >
                            <b-col cols="1"></b-col>
                            <b-col cols="11">
                              <i class="fa fa-chevron-down"></i>
                              <span> {{ StoreLangTranslation.data['no_scope'][StoreLang] }}</span>
                           </b-col>
                          </b-row>
                          <b-collapse :id="'project_collapse_no_scope'" :visible="false">
                            <template v-if="(!project['wanalyzer_project_scope'] || (project['wanalyzer_project_scope'] && project['wanalyzer_project_scope'] == '')) && f_searchItem(project.name[StoreLang])" v-for="(project, project_index) in project_list">
                              <b-dropdown-item-button @click="f_selectProject(project_type, project_index)" :style="d_selectedProjectType === project_type && project_index === d_selectedProjectIndex ? 'background-color: #a7d2ff;' : ''">
                                <b-row>
                                  <b-col cols="1"></b-col>
                                  <b-col cols="11">
                                    {{ project.name[StoreLang] }}
                                  </b-col>
                                </b-row>
                              </b-dropdown-item-button>
                            </template>
                          </b-collapse>
                        </template>
                      </template>
                      <template v-else v-for="(project, project_index) in project_list">
                        <template v-if="f_searchItem(project.name[StoreLang])">
                          <b-dropdown-item-button @click="f_selectProject(project_type, project_index)" :style="d_selectedProjectType === project_type && project_index === d_selectedProjectIndex ? 'background-color: #a7d2ff;' : ''">
                            <span>
                              {{ project.name[StoreLang] }}
                            </span>
                          </b-dropdown-item-button>
                        </template>
                      </template>
                    </b-dropdown-group>
                    <b-dropdown-divider></b-dropdown-divider>
                  </template>
                </template>
              </div>
          </b-dropdown>
        </b-col>
        <b-col sm="12" lg="3"></b-col>
        <b-col sm="12" lg="2">
          <template v-if="d_userAuthorizationListLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-dropdown id="dropdown-grouped" variant="outline-white" no-caret style="width: 100%;">
              <template #button-content>
                <i class="fa fa-plus">
                  {{ StoreLangTranslation.data['new_project'][StoreLang] }}
                </i>
              </template>
              <b-row style="width: 100%;">
                <b-col sm="12" lg="10">
                  <b-form-input v-model="d_searchAuthorization" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
                </b-col>
                <b-col sm="12" lg="2">
                  <i class="fa fa-trash" style="cursor: pointer;" @click="d_searchAuthorization = ''"></i>
                </b-col>
              </b-row>
              <template v-for="auth_code in d_useAuthCodeList">
                  <template v-if="d_userAuthorizationList[auth_code] && d_userAuthorizationList[auth_code].length > 0">
                    <b-dropdown-group :id="auth_code" :header="StoreLangTranslation.data[auth_code][StoreLang]" style="min-width: 500px;">
                      <template v-for="(user_auth_data, user_auth_data_index) in d_userAuthorizationList[auth_code]">
                        <b-dropdown-item-button @click="f_useThisAuth(auth_code, user_auth_data)">
                          {{ user_auth_data.auth_data['label'] }}
                          <template v-if="user_auth_data.auth_data && user_auth_data.auth_data['data'] && user_auth_data.auth_data['data']['0'] && user_auth_data.auth_data['data']['0']['4'] && user_auth_data.auth_data['data']['0']['4']['val'] && user_auth_data.auth_data['data']['0']['4']['val']['value'] === '1'">
                            <template v-if="user_auth_data.auth_data['data']['0']['7'] && user_auth_data.auth_data['data']['0']['7']['val']">
                              <span> {{ user_auth_data.auth_data['data']['0']['7']['val'] }} </span>
                            </template>
                            <template v-else>
                              <span>0</span>
                            </template>
                            <span> / </span>
                            <template v-if="user_auth_data.auth_data['data']['0']['6'] && user_auth_data.auth_data['data']['0']['6']['val']">
                              <span> {{ user_auth_data.auth_data['data']['0']['6']['val'] }} </span>
                            </template>
                          </template>
                        </b-dropdown-item-button>
                      </template>
                    </b-dropdown-group>
                    <b-dropdown-divider></b-dropdown-divider>
                  </template>
                </template>
            </b-dropdown>
          </template>
        </b-col>
        <b-col sm="12" lg="1">
          <b-button class="pull-right" size="lg" variant="white" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
            <i class="fa fa-desktop"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-row :style="d_fullScreen ? 'margin-top: 50px;' : 'margin: 0px;'">
        <b-col sm="12" lg="12">
          <template v-if="d_selectedProjectIndex !== ''">

              <template v-if="d_selectedProjectType === 'wanalyzer_stats_view'">
                <b-row>
                  <b-col sm="12" lg="1"></b-col>
                  <b-col sm="12" lg="10">
                    <wanalyzer-stats-view :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :p_statsId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_stats_id" :p_authClientId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].auth_data.client_id"></wanalyzer-stats-view>
                  </b-col>
                  <b-col sm="12" lg="1"></b-col>
                </b-row>
              </template>

              <template v-else-if="d_selectedProjectType === 'wanalyzer_dataset_filter'">
                  <wanalyzer-dataset-analyze :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :f_getUserAuthorizationList="f_getUserAuthorizationList" :p_selectedProjectType="d_selectedProjectType" :p_projectPermission="d_projectList[d_selectedProjectType][d_selectedProjectIndex]" :p_fullScreen="d_fullScreen" ></wanalyzer-dataset-analyze>
              </template>

              <template v-else-if="d_selectedProjectType === 'wanalyzer_dataset_stats_query'">
                  <wanalyzer-dataset-analyze :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :f_getUserAuthorizationList="f_getUserAuthorizationList" :p_selectedProjectType="d_selectedProjectType" :p_projectPermission="d_projectList[d_selectedProjectType][d_selectedProjectIndex]" :p_fullScreen="d_fullScreen" ></wanalyzer-dataset-analyze>
              </template>

              <template v-else-if="d_selectedProjectType === 'wanalyzer_machinelearning_query'">
                <b-row>
                  <b-col sm="12" lg="1"></b-col>
                  <b-col sm="12" lg="10">
                  <machine-learning-query :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :p_machineLearningId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_machine_learning_id" :p_authClientId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].auth_data.client_id"></machine-learning-query>
                  </b-col>
                  <b-col sm="12" lg="1"></b-col>
                </b-row>

              </template>

              <template v-else-if="d_selectedProjectType === 'wanalyzer_cell_calculate'">
                  <wanalyzer-cell-calculate :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :p_idType="d_projectList[d_selectedProjectType][d_selectedProjectIndex].id_type" :p_authClientId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].auth_data.client_id" :p_fullScreen="d_fullScreen" ></wanalyzer-cell-calculate>
              </template>
            
              <template v-else-if="d_selectedProjectType === 'wanalyzer_create_query'">
                <wanalyzer-dataset-analyze :key="d_selectedProjectType + '_' + d_selectedProjectIndex" :p_projectId="d_projectList[d_selectedProjectType][d_selectedProjectIndex].wanalyzer_project_id" :f_getUserAuthorizationList="f_getUserAuthorizationList" :p_selectedProjectType="d_selectedProjectType" :p_projectPermission="d_projectList[d_selectedProjectType][d_selectedProjectIndex]" :p_fullScreen="d_fullScreen" :p_scopeList="d_scopeList" :f_getScopes="f_getScopes" :p_projectList="d_projectList" :p_selectedProjectIndex="d_selectedProjectIndex"></wanalyzer-dataset-analyze>
              </template>
            
          </template>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_supportData.show" id="d_supportData_show" v-model="d_supportData.show" scrollable hide-footer :title="StoreLangTranslation.data['support_screen'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12"></b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsRoute } from '@/wam/modules/route';
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import {
  default as MachineLearningQuery
} from "@/components/widgets/MachineLearningQuery";
import {
  default as WanalyzerCellCalculate
} from "@/components/widgets/WanalyzerCellCalculate";
import {
  default as WanalyzerDatasetAnalyze
} from "@/components/widgets/WanalyzerDatasetAnalyze";
import {
  default as WanalyzerStatsView
} from "@/components/widgets/WanalyzerStatsView";
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
  name: 'UserProjectProcess',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
    MachineLearningResult,
    MachineLearningQuery,
    WanalyzerCellCalculate,
    WanalyzerDatasetAnalyze,
    WanalyzerStatsView,
  },
  props: {},
  data () {
    return {
      d_fullScreen: true,
      d_useCreateEligibleAuthCodeList: ['wanalyzer_create_query'],
      d_useAuthCodeList: ['wanalyzer_stats_view','wanalyzer_dataset_filter','wanalyzer_dataset_stats_query','wanalyzer_machinelearning_query','wanalyzer_create_query'],
      d_searchAuthorization: '',
      d_userAuthorizationList: {},
      d_userAuthorizationListLoading: false,
      d_selectedWanalyzerCreateQuery: 'general_settings',
      d_selectedProjectType: '',
      d_selectedProjectIndex: '',
      d_projectList: {
        'wanalyzer_stats_view': [],
        'wanalyzer_dataset_filter': [],
        'wanalyzer_dataset_stats_query': [],
        'wanalyzer_machinelearning_query': [],
        'wanalyzer_create_query': [],
      },
      d_testProjectList: {
        "wanalyzer_stats_view": [
            {
              "name": {"en": "ALK Pozitif istatistiği", "tr": "ALK Pozitif istatistiği"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "Akciğer Kanseri Genetik ALK Pozitif [evideep1]", "en": "Lung Cancer Genetic ALK Positive [evideep1]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Akciğer Kanseri Genetik ALK Pozitif Projesi",
              "wanalyzer_project_id": "615a309c-fa5c-4d23-aedd-2c0d08443459",
              "wanalyzer_stats_name": "ALK Pozitif istatistiği",
              "wanalyzer_stats_id": "5b13faea-3dc3-4a24-852e-14ba80dd084d",
              "wanalyzer_machine_learning_name": "",
              "wanalyzer_machine_learning_id": "",
            }
        ],
        "wanalyzer_dataset_filter": [
            {
              "name": {"en": "Akciğer Kanseri Genetik ALK Pozitif Projesi", "tr": "Akciğer Kanseri Genetik ALK Pozitif Projesi"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "Akciğer Kanseri Genetik ALK Pozitif [evideep1]", "en": "Lung Cancer Genetic ALK Positive [evideep1]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Akciğer Kanseri Genetik ALK Pozitif Projesi",
              "wanalyzer_project_id": "615a309c-fa5c-4d23-aedd-2c0d08443459",
              "wanalyzer_stats_name": "",
              "wanalyzer_stats_id": "",
              "wanalyzer_machine_learning_name": "",
              "wanalyzer_machine_learning_id": "",
            }
        ],
        "wanalyzer_dataset_stats_query": [
            {
              "name": {"en": "Akciğer Kanseri Genetik ALK Pozitif Projesi", "tr": "Akciğer Kanseri Genetik ALK Pozitif Projesi"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "Akciğer Kanseri Genetik ALK Pozitif [evideep1]", "en": "Lung Cancer Genetic ALK Positive [evideep1]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Akciğer Kanseri Genetik ALK Pozitif Projesi",
              "wanalyzer_project_id": "615a309c-fa5c-4d23-aedd-2c0d08443459",
              "wanalyzer_stats_name": "",
              "wanalyzer_stats_id": "",
              "wanalyzer_machine_learning_name": "",
              "wanalyzer_machine_learning_id": "",
            }
        ],
        "wanalyzer_machinelearning_query": [
            {
              "name": {"en": "Overall Survival Analyze For Lung Cancer And Alk Gene Positive Patients", "tr": "Overall Survival Analyze For Lung Cancer And Alk Gene Positive Patients"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "Akciğer Kanseri Genetik ALK Pozitif [evideep1]", "en": "Lung Cancer Genetic ALK Positive [evideep1]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Overall Survival Analyze For Lung Cancer And Alk Gene Positive Patients",
              "wanalyzer_project_id": "5ca4d0bc-7057-444b-ba93-eab11a92b524",
              "wanalyzer_stats_name": "",
              "wanalyzer_stats_id": "",
              "wanalyzer_machine_learning_name": "Overall Survival Analyze For Lung Cancer And Alk Gene Positive Patients",
              "wanalyzer_machine_learning_id": "f1adb953-ead3-44c2-b00d-fedcd77f1002",
            }
        ],
        "wanalyzer_create_query": [
            {
              "name": {"en": "Tansiyon hastalığı olan vakalar", "tr": "Tansiyon hastalığı olan vakalar"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "İstanbul Hastaneleri Veri Sorgulama [evideep11]", "en": "Istanbul Hospitals Data Query [evideep11]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Tansiyon hastalığı olan vakalar",
              "wanalyzer_project_create_date": "2024-10-19T20:00:00.000000",
              "wanalyzer_project_id": "8bbaef08-a8a6-416f-9dc4-565a7a149582",
              "wanalyzer_stats_name": "",
              "wanalyzer_stats_id": "",
              "wanalyzer_machine_learning_name": "",
              "wanalyzer_machine_learning_id": "",
              "layer_list": [
                {
                  "layer": {"name": {"en": "patient", "tr": "hasta"}, "value": "hospital_patient_1"},
                  "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
                  "parameter_list": ""
                },
                {
                  "layer": {"name": {"en": "patient / visit", "tr": "hasta / başvuru"}, "value": "hospital_patient_2"},
                  "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
                  "parameter_list": ""
                },
                {
                  "layer": {"name": {"en": "patient / visit / diagnosis", "tr": "hasta / başvuru / tanı"}, "value": "hospital_patient_3"},
                  "parameter_usage_type": {"name": {"en": "all", "tr": "hepsi"}, "value": "0"},
                  "parameter_list": ""
                }
              ],
              "main_layer": "hospital_patient_1",
              "layer_tree_list": [
                {"name": {"en": "hospital patient", "tr": "hastane hasta"}, "value": "hospital_patient"}
              ],
              "layer_tree_list_set": {"name": {"en": "hospital patient", "tr": "hastane hasta"}, "value": "hospital_patient"},
              "location_list": [
                {
                  "name": {"en": "wtransfer1", "tr": "wtransfer1"},
                  "value": "wtransfer1",
                  "selected": 1,
                  "server_list": [
                    {"name": {"en": "MP - BAH", "tr": "MP - BAH"}, "value": "4", "selected": 0},
                    {"name": {"en": "MP - BHI", "tr": "MP - BHI"}, "value": "6", "selected": 0},
                    {"name": {"en": "MP - FLR", "tr": "MP - FLR"}, "value": "11", "selected": 0},
                    {"name": {"en": "MP - GEB", "tr": "MP - GEB"}, "value": "12", "selected": 0},
                    {"name": {"en": "MP - GOZ", "tr": "MP - GOZ"}, "value": "13", "selected": 1},
                    {"name": {"en": "MP - GZU", "tr": "MP - GZU"}, "value": "15", "selected": 0},
                    {"name": {"en": "MP - MLT", "tr": "MP - MLT"}, "value": "18", "selected": 0},
                  ]
                }
              ],
              "case_limit_count_set": "100",
              "case_limit_count": "10000",
              "main_rule_algorithm": {"query_list":[{"time_of_value":"any_value","data_type":"old_recorded_data","compile_str_array":["(",0,")"],"fields":[{"loc":[{"type":"group","value":"0","label":"Param"},{"type":"select","value":"2","label":"department"}],"using_data_if_not_reaching_record_data":"true","val_list":[{"translation":{"en":"medical oncology","tr":"medikal onkoloji"},"value":"102","label":"medikal onkoloji"}],"values":["102"],"significant":"true","value_type":"select","operation":"="}],"compile_str":"","significant":"true","date":"1","type":{"value":"hospital_patient_2","label":"patient visit"}}],"compile_str_array":["(",0,")"],"compile_str_date":[],"compile_str_text":[]},
              "main_rule_algorithm_set": {"query_list":[{"time_of_value":"any_value","data_type":"old_recorded_data","compile_str_array":["(",0,")"],"fields":[{"loc":[{"type":"group","value":"0","label":"Param"},{"type":"select","value":"2","label":"department"}],"using_data_if_not_reaching_record_data":"true","val_list":[{"translation":{"en":"medical oncology","tr":"medikal onkoloji"},"value":"102","label":"medikal onkoloji"}],"values":["102"],"significant":"true","value_type":"select","operation":"="}],"compile_str":"","significant":"true","date":"1","type":{"value":"hospital_patient_2","label":"patient visit"}}],"compile_str_array":["(",0,")"],"compile_str_date":[],"compile_str_text":[]}
            }
        ],
        "wanalyzer_cell_calculate": [
            {
              "name": {"en": "Tansiyon hastalığı olan vakalar", "tr": "Tansiyon hastalığı olan vakalar"},
              "shop_name":{"en": "Evideep Market", "tr": "Evideep Market"},
              "shop_client_id": "6da6233a-38ee-4fbe-946b-e3152709b789",
              "shopcart_bundle_name": {"tr": "Akciğer Kanseri Genetik ALK Pozitif [evideep1]", "en": "Lung Cancer Genetic ALK Positive [evideep1]"},
              "shopcart_bundle_client_id": "evideep_47603a1b-63a5-4d29-ab99-f0f0c309dc8a",
              "shopcart_product_name": {"tr": "akciğer kanseri genetik alk son 1 hafta pozitif aboneliği yıllık", "en": "lung cancer genetic alk positive subscription annual"},
              "shopcart_product_client_id": "9bab4cdd-b066-4099-8767-dabc13f6ba7d",
              "wanalyzer_project_name": "Tansiyon hastalığı olan vakalar",
              "wanalyzer_project_id": "8bbaef08-a8a6-416f-9dc4-565a7a149582",
              "wanalyzer_stats_name": "",
              "wanalyzer_stats_id": "",
              "wanalyzer_machine_learning_name": "",
              "wanalyzer_machine_learning_id": "",
              "id_type": "client_id_based",
            }
        ]
      },
      d_supportData: {
        'maximum_characters_name': 100,
        'maximum_characters_note': 500,
        'mail_status': true,
        'phone_status': true,
        'phone': '',
        'mail': '',
        'show': false,
        'edit_eligible': false,
        'operation_name': '',
        'operation_note': '',
        'operation_note_additional': '',
        'maximum_characters': 500,
        'subject': 'genel'
      },
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_userSuppportList: [],
      d_scopeList: [],
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_user && this.d_user.username) {
      this.f_getUserAuthorizationList();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
  },
  methods: {
    f_useThisAuth: function (auth_code, user_auth_data) {
      if (auth_code === 'wanalyzer_create_query') {
        this.d_selectedProjectType = '';
        this.d_selectedProjectIndex = '';
        setTimeout(function () {
          let new_permission_item = JSON.parse(JSON.stringify(user_auth_data));
          for (let i in new_permission_item.name) {
            if (i === 'tr') {
              new_permission_item.name[i] = 'yeni proje'
            } else if (i === 'en') {
              new_permission_item.name[i] = 'new project'
            }
          }
          if (!this.d_projectList[auth_code]) {
            this.d_projectList[auth_code] = [];
          }
          this.d_projectList[auth_code].push(new_permission_item);
          this.d_selectedProjectType = auth_code;
          this.d_selectedProjectIndex = this.d_projectList[auth_code].length - 1;
          let new_query = {
            'path': this.$route.path,
            'query': {}
          };
          this.$router.push(new_query);
        }.bind(this), 100)

      }
    },
    f_getUserAuthorizationList: function () {
      this.d_userAuthorizationListLoading = true;
      let query = 'user_client_id=' + this.d_user.username;
      // auth_status=active, passive
      query += '&auth_status=active';
      // auth_type=general, product, shop
      // query += '&auth_type=' + auth_type;
      let data = {};
      this.d_userAuthorizationList = {};
      this.d_projectList = {
        'wanalyzer_stats_view': [],
        'wanalyzer_dataset_filter': [],
        'wanalyzer_dataset_stats_query': [],
        'wanalyzer_machinelearning_query': [],
        'wanalyzer_create_query': []
      };
      ServiceFunctions.user_authorization_list(query, data)
        .then(resp => {
          this.d_userAuthorizationListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            for (let i in resp.data.list) {
              let wanalyzer_project_id = '';
              try {
                wanalyzer_project_id = resp.data.list[i].auth_data.data['0']['12']['val'];
              } catch(err) {}

              let wanalyzer_stats_id = '';
              try {
                wanalyzer_stats_id = resp.data.list[i].auth_data.data['0']['13']['val'];
              } catch(err) {}

              let wanalyzer_machine_learning_id = '';
              try {
                wanalyzer_machine_learning_id = resp.data.list[i].auth_data.data['0']['14']['val'];
              } catch(err) {}
              let auth_code = '';
              try {
                auth_code = resp.data.list[i].auth_data.data['0']['1']['val'];
              } catch(err) {}
              if (auth_code) {
                if (wanalyzer_project_id || wanalyzer_stats_id || wanalyzer_machine_learning_id) {
                  if (!this.d_projectList[auth_code]) {
                    this.d_projectList[auth_code] = [];
                  }
                  this.d_projectList[auth_code].push(resp.data.list[i]);
                } else {
                  if (this.d_useCreateEligibleAuthCodeList.indexOf(auth_code) !== -1) {
                    if (!this.d_userAuthorizationList[auth_code]) {
                      this.d_userAuthorizationList[auth_code] = [];
                    }
                    this.d_userAuthorizationList[auth_code].push(resp.data.list[i]);
                  }
                }
              }
            }

            if (this.$route.query.project_id && this.$route.query.project_type) {
              if (this.d_projectList[this.$route.query.project_type]) {
                for (let project_index in this.d_projectList[this.$route.query.project_type]) {
                  let project_data = this.d_projectList[this.$route.query.project_type][project_index];
                  if (project_data.wanalyzer_project_id === this.$route.query.project_id) {
                    this.d_selectedProjectType = this.$route.query.project_type;
                    this.d_selectedProjectIndex = parseInt(project_index);
                    break;
                  }
                }
              }
            }
            this.f_getScopes();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_searchInProjectList: function (project_list) {
      let res = false;
      if (this.d_searchText) {
        for (let project_index in project_list) {
            try {
              if (project_list[project_index].name[this.StoreLang].toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
                res = true;
                break;
              }
            } catch(err) {}
        }
      } else {
        res = true;
      }
      return res;
    },
    f_searchItem: function (searching_text) {
      let res = false;
      if (this.d_searchText) {
        try {
          if (searching_text.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
            res = true;
          }
        } catch(err) {}
      } else {
        res = true;
      }
      return res;
    },
    f_selectProject: function (project_type, project_index) {
      this.d_searchText = '';
      this.d_selectedProjectType = project_type;
      this.d_selectedProjectIndex = project_index;
      // console.log('this.$route : ', this.$route);
      let route_path = '';
      if (this.d_selectedProjectType === 'wanalyzer_dataset_filter') {
        route_path = ClsRoute.f_getRoutePath('user_project_dataset_filter', this.StoreLang);
      } else if (this.d_selectedProjectType === 'wanalyzer_dataset_stats_query') {
        route_path = ClsRoute.f_getRoutePath('user_project_dataset_statistics_query', this.StoreLang);
      } else if (this.d_selectedProjectType === 'wanalyzer_machinelearning_query') {
        route_path = ClsRoute.f_getRoutePath('user_project_machine_learning', this.StoreLang);
      } else if (this.d_selectedProjectType === 'wanalyzer_stats_view') {
        route_path = ClsRoute.f_getRoutePath('user_project_statistics_view', this.StoreLang);
      } else if (this.d_selectedProjectType === 'wanalyzer_create_query') {
        route_path = ClsRoute.f_getRoutePath('user_project_create_data_query', this.StoreLang);
      }
      // console.log('route_path : ', route_path);
      let new_query = {
        'path': route_path,
        'query': {
          'project_id': '',
          'project_type': project_type
        }
      };

      if (this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_project_id) {
        new_query.query.project_id = this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_project_id
      }
      if (this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_stats_id) {
        new_query.query.stats_id = this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_stats_id;
      }
      if (this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_machine_learning_id) {
        new_query.query.ml_id = this.d_projectList[this.d_selectedProjectType][this.d_selectedProjectIndex].wanalyzer_machine_learning_id;
      }
      this.$router.push(new_query);
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:MM");
          return x;
        }
      } else {
        return "";
      }
    },
    f_supportModal: function () {
      if (this.d_user) {
        this.d_supportData.show = true;
        this.d_supportData.edit_eligible = true;
        this.d_supportData.operation_name = '';
        this.d_supportData.operation_note = '';
      } else {
        this.$router.push({ path: '/login', query: {} });
      }
    },
    f_getScopes: function () {
      this.d_scopeList = [];
      for (let project of this.d_projectList['wanalyzer_create_query']) {
        if (project['wanalyzer_project_scope']){
          if (this.d_scopeList.indexOf(project['wanalyzer_project_scope']) === -1) {
            this.d_scopeList.push(project['wanalyzer_project_scope'])
          }
        }
      }
    }
  },
  watch: {}
};

</script>
<style type="text/css">

.normal-mode {}

.full-screen-mode {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
/*  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
*/
}

</style>
